import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import './contact.scss';
import { Helmet } from "react-helmet-async";
import { Header } from "./header";
import { BottomHooks } from "./bottomHooks";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export function Contact() {
  const { t } = useTranslation();
  const heroSectionRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('contact');
    return () => {
      document.body.classList.remove('contact');
    };
  }, []);

  useGSAP(() => {
    // const mm = gsap.matchMedia();
    gsap.to("h1", {
      opacity: 1,
      scale: 1.8,
      duration: 3,
      delay: 1,
      ease: "power3.out",
    });
    gsap.to("h1", {
      opacity: 0,
      scale: 0,
      duration: 2,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("h2", {
      opacity: 1,
      scale: 1.8,
      duration: 2,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("h2", {
      opacity: 0,
      scale: 0,
      duration: 2,
      delay: 1,
      ease: "power3.out",
    }, "-=0.1");
    gsap.to("a", {
      opacity: 1,
      scale: 0.8,
      duration: 3,
      delay: 0,
      ease: "power3.out",
    }, "-=0.1");
    
  }, {scope: heroSectionRef});

  return (
    <>
      <Helmet>
        <title>{t('contactSeoTitle')}</title>
        <meta name="description" content={t('contactSeoDescription')} />
        <meta property="og:title" content={t('contactSeoTitle')} />
        <meta property="og:description" content={t('contactSeoDescription')} />
      </Helmet>
      <Header />
      <section id="heroContact" ref={heroSectionRef}>
        <h1 style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>{t('contactHeadingOne')}</h1>
        <h2 style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}}>{t('contactHeadingTwo')}</h2>
        <a style={{transform: "translate(-50%, -50%) scale(0)", opacity: 0}} target="_blank" rel="noreferrer" href={`mailto:${t('contactMail')}`}>{t('contactMail')}</a>
      </section>
      <BottomHooks transalteHooksHeading='bottomHooksHeadingContact' transalteHooksSpan='bottomHooksSpanContact' />
    </>
  )
}